<template>
	<div id="plannedShift">
		<el-radio-group v-model="flags" @change="flageChange">
			<el-radio-button label="0">大间隔</el-radio-button>
			<el-radio-button label="1">串车</el-radio-button>
		</el-radio-group>
		<jy-query ref="form" :model="formData" class="formInfo">
			<jy-query-item prop="startTime" label="开始时间">
				<el-date-picker
					v-model="formData.startTime"
					:picker-options="startTime"
					value-format="yyyy-MM-dd"
					type="date"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<!-- <el-col :span="2" class="item">至</el-col> -->
			<jy-query-item prop="endTime" label="结束时间">
				<el-date-picker
					v-model="formData.endTime"
					:picker-options="endTime"
					value-format="yyyy-MM-dd"
					type="date"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item prop="routeId" label="线路">
				<el-select v-model="formData.routeIds" multiple>
					<el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" :disabled="isSearch">查询</el-button>
				<el-button type="primary" plain @click="resetForm('formInline')">重置</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="largeTable" :data="dataShow">
			<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
			<jy-table-column prop="time" label="时间" min-width="100" fixed="left"></jy-table-column>
			<jy-table-column prop="routeNa" label="线路" min-width="100" fixed="left"></jy-table-column>
			<jy-table-column label="方向" min-width="160">
				<template slot-scope="scope">{{ scope.row.schemeT | schemeT }}</template>
			</jy-table-column>
			<jy-table-column prop="cars" label="事件车辆" min-width="100"></jy-table-column>
			<jy-table-column prop="realSpace" label="间隔站点数" min-width="150"></jy-table-column>
			<jy-table-column prop="setSpace" label="大间隔站点数" min-width="150" v-if="flags == '0'"></jy-table-column>
			<jy-table-column prop="setSpace" label="串车距离" min-width="150" v-if="flags == '1'"></jy-table-column>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
	</div>
</template>
<script>
import { btnMixins } from "@/common/js/button.mixin";
import dayjs from "dayjs";
export default {
	data() {
		return {
			formData: {
				routeIds: "",
				startTime: "",
				endTime: "",
				flag: "0"
			},
			flags: "0",
			isSearch: true,
			largeList: [],
			routeList: [],
			// 分页
			pageIndex: 1,
			total: 0,
			pageSize: 10,
			pageNum: 1,
			totalPageData: [],
			dataShow: [],
			startTime: {
				disabledDate: time => {
					if (this.formData.endTime) {
						return dayjs(time) < dayjs("2024-05-01") || time.getTime() > new Date(this.formData.endTime).getTime();
					} else {
						return dayjs(time) < dayjs("2024-05-01") || time.getTime() > Date.now() - 8.64e7;
					}
				}
			},
			endTime: {
				disabledDate: time => {
					if (this.formData.startTime) {
						return (
							dayjs(time) < dayjs("2024-05-01") ||
							time.getTime() > Date.now() - 8.64e7 ||
							time.getTime() <= new Date(this.formData.startTime).getTime() - 8.64e7 //这里的this.params.beginDate按自己的字段参数来
						);
					} else {
						return dayjs(time) < dayjs("2024-05-01") || time.getTime() > Date.now() - 8.64e7;
					}
				}
			}
		};
	},
	watch: {
		formData: {
			handler(val) {
				if (val.startTime != "" && val.endTime != "" && val.routeIds != "") {
					this.isSearch = false;
				} else {
					this.isSearch = true;
				}
			},
			deep: true
		}
	},
	mixins: [btnMixins],
	filters: {
		schemeT(val) {
			if (val == 1) {
				return "上行";
			} else if (val == 2) {
				return "下行";
			}
		}
	},
	async created() {
		await this.getRouteList();
	},
	methods: {
		async getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			await this.$http.post(url).then(({ detail }) => {
				this.routeList = detail;
				if (this.routeList.length) {
					this.formData.routeId = this.routeList[0].routeId;
				}
			});
		},
		getList() {
			let option = {
				...this.formData
			};
			this.pageIndex = 1;
			let url = "/stat/exception/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				detail.forEach(item => {
					item.cars = item.opException.vehicleNo1 + "、" + item.opException.vehicleNo2;
					item.realSpace = item.opException.realSpace ? item.opException.realSpace : "";
					item.setSpace = item.opException.setSpace ? item.opException.setSpace : "";
					item.schemeT = item.opException.schemeT ? item.opException.schemeT : "";
					return {
						...item
					};
				});
				this.largeList = detail;
				this.total = this.largeList.length;
				this.calcPageData();
			});
		},
		// 查询表格
		oncheck() {
			this.getList();
		},
		// 重置
		resetForm() {
			this.formData.routeIds = [];
			this.formData.startTime = "";
			this.formData.endTime = "";
			this.pageIndex = 1;
			this.getList();
		},
		// 分页
		calcPageData() {
			this.dataShow = [];
			this.totalPageData = [];
			if (this.largeList.length > 1) {
				this.pageNum = Math.ceil(this.largeList.length / this.pageSize) || 1;
			}
			for (let i = 0; i < this.total; i++) {
				this.totalPageData[i] = this.largeList.slice(this.pageSize * i, this.pageSize * (i + 1));
			}
			this.dataShow = this.totalPageData[this.pageIndex - 1];
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.calcPageData();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.dataShow = this.totalPageData[val - 1];
		},
		flageChange(val) {
			this.$set(this.formData, "flag", val);
			console.log(this.formData);
		}
	}
};
</script>
<style lang="scss" scoped="plannedShift">
#plannedShift {
}
.formInfo {
	margin-top: 20px;
}
.item {
	height: 50px;
	text-align: center;
	padding-top: 5px;
	font-size: 16px;
}
</style>
